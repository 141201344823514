* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

*[hide] {
  pointer-events: none;
  opacity: 0.7;
}

.wrapper-div {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-image: linear-gradient(174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);
  $nav-panel-grow : 10;

  .nav-panel {
    background: linear-gradient(174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);
    width: 20%;
    margin: 5px;
    border: 0.5px solid white;
    display: flex;
    flex-direction: column;

    .heading {
      // border: 2px solid black;
      position: relative;
      width: 100%;
      text-align: center;
      margin: 20px 0;
    }

    .nav-controls {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .link {
        // border: 2px solid black;
        width: 100%;
        padding: 25px 15px;
        height: 2em;
        font-size: 1.25em;
        // padding-left: 15px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: black;
        border-radius: 5px;

        &.active {
          background-color: rgb(255, 180, 119);
          font-weight: 550;
        }

        &:hover {
          box-shadow: 0 0 10px 2px rgb(127, 127, 127);
        }
      }

      .btns {
        padding-bottom: 20px;

        &>* {
          width: 80%;
          margin: 10px 10%;
          height: 3em;
        }

        .session-timer {
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 1.5em;
          border-radius: 5px;
          height: 1.75em;
          background-color: rgb(105, 201, 105);
        }

        .refresh {
          background-color: green;
        }

        .logout {
          background-color: rgb(255, 46, 46);
        }


      }
    }
  }

  .page-view {
    // border: 2px solid black;
    width: 100%;
  }

}

// Generic styles
.toolbar {
  // border: 2px solid black;
  background-color: rgba(255, 184, 98, 0.56);
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;

  button {
    height: 80%;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.1em;
  }

  .message {
    font-size: 1.5em;
    padding-left: 20px;
  }
}

.upload-result-photos-wrapper {
  // toolbar is generic

  .view {
    display: flex;
    flex-wrap: wrap;

    .file-element {
      display: flex;
      align-items: center;
      font-size: 1.25em;
      min-width: 300px;
      border: 2px solid rgb(158, 158, 158);
      margin: 5px;
      height: 2em;
      border-radius: 3px;
      padding-left: 5px;
      position: relative;

      .delete-btn {
        width: 2em;
        height: 2em;
        position: absolute;
        right: 0px;
        margin-right: 5px;
      }
    }
  }

  .warning-view {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .message-box {
      background-color: wheat;
      width: 50%;
      position: relative;

      .close {
        float: right;
        margin: 10px;
        height: 30px;
        width: 30px;
      }

      .name-list {
        margin: 40px 20px 30px;

        .head {
          font-weight: 400;
          font-size: 1.4em;
          margin-bottom: 5px;
        }

        .entry {
          margin-top: 3px;
          font-size: 1.2em;
        }
      }
    }
  }
}

.select-top-photos-wrapper {
  // toolbar is generic
  height: 100vh;
  display: flex;
  flex-direction: column;

  .view {
    padding: 20px;
    flex-grow: 1;
    background-color: rgb(255, 247, 226);
    position: relative;
  }

}

.arrange-result-photos-wrapper {
  // background-color: aqua;
  display: flex;
  height: 100%;
  width: 100%;
  $gap: 20px;
  padding: 0 $gap $gap 0;

  &>div {
    // border: 2px solid black;
    width: 50%;
    margin: $gap 0 0 $gap;
  }
}

.managing-list {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  // width: 100%;

  .btn-div {
    height: 60px;

    &>span {
      position: absolute;
      left: 5px;
      top: 5px;
      max-width: 180px;
    }

    h1 {
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 10px 0;

      .btn {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

  }

  .view {
    flex-grow: 1;
    display: flex;
    overflow: hidden;

    .list-div {
      flex-grow: 1;
      border-radius: 5px;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.399);
      margin: 10px;
      margin-right: 0;
      overflow: auto;
    }

    .nav-btn-div {
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .move-btn {
        // border: 2px solid black;
        background-color: rgba(4, 142, 255, 0.326);
        width: 3em;
        height: 3em;
      }
    }

    .handle {
      text-align: center;
      cursor: move;
      background-color: rgb(255, 255, 238);

    }

    .img-preview {
      width: 35%;
      height: auto;
      margin: 70px 10px 0 10px;
      top: 0;
      right: 0;
      position: absolute;
    }

  }

}

.login-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);
  display: flex;
  justify-content: center;
  align-items: center;

  .login-div {
    background-color: white;
    padding: 45px;
    display: flex;
    flex-direction: column;
    width: clamp(400px, 50%, 400px);
    padding: 45px;

    h1 {
      margin-bottom: 15px;
    }

    &>* {
      margin-top: 20px;
    }

    .submit-btn {

      font-size: 1.25em;
      height: 2.5em;
      margin-top: 30px;
    }

    .error-text {
      color: rgb(158, 0, 0);
    }
  }
}